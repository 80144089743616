import {
  SET_WEB_PUBLIC, SET_WEB_CONFIG, SET_MAINTAIN_DATA, SET_IS_OPEN_ANNOUNCEMENT,
  SET_IS_OPEN_LICENSE, SET_IS_CONTACT, SET_IS_AUDIO, SET_IS_UNCLAIMED,
  SET_IS_SHOW_RECHARGE_SUCCESS,
  SET_IS_PIX_PAY_OPEN,
  SET_IS_OPEN_DAILY,
  SET_IS_OPEN_EXTRA,
  SET_FORCE_GAME_DATA
} from '../constants'
import { deepClone } from '@utils'

interface Action {
  type: string;
  value: any;
}

/**
 * template
 * 0: 默认黑色主题
 * 1: 新版黑色主题
 * 2: 默认白色主题
 * 3: 新版白色主题
 */
const templates: Record<any, string> = {
  0: 'originalDark',
  1: 'gradientDark',
  2: 'originalLight',
  3: 'gradientLight',
  4: 'phlVipTemp'
}

const initValue: any = {
  webPublic: {
    template: ''
  },
  webConfig: {},
  maintainData: {
    open: 0,
    web_time: '',
    text: '',
    customer_service: '',
    CS: ''
  },
  isOpenAnnouncement: false,
  isOpenLicense: false,
  isContact: false,
  isAudio: '',
  isUnclaimed: false,
  isPixPayOpen: false,
  isShowRechargeSuccess: false,
  isOpenDaily: false,
  isOpenExtra: false,
  forceGameModal: {
    show: false,
    img: ''
  },
  cryptoKey: `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA4ST4Hsl27gFCI8kY7CRx
0pev4qhnnF05ZUO1zAVEVRKlCcHm5w9oeSSYC5Z1CNFsS3yWNJDAincmq92CoxSN
PD4DyBJw0eaV7mlOSymolm9RV2edTZhEaxwr5WCHgv1OCf4coiMPwGxO9w4F/wqL
/ZeTyW9x5fj6L+DxsvsT9iUG1go+Nuh5NqQ2uG25z2NPmFHmVp9IUOn3eVXfOT1S
ZWEJJIF3dGgeMEY5r/rpScbZJiiQXCHJGfmAqfM7QivcFKYH4/pHm7zMuANrRNL3
bAGa2ChRQE6sDq/KaGf+PKx8jP7+rj7CYp6HqxIiaJZe4f86nHz3zhlbqmGBmwVC
GQIDAQAB`
}

export default (state: any = initValue, action: Action) => {
  const _state = deepClone(state)
  let template = templates['0']
  if (action.type === SET_WEB_PUBLIC) {
    template = templates[String(action.value.typesetting?.find((item: any) => item.type === 1)?.marked) ?? '0']
  }

  // template = 'originalLight'
  // template = 'gradientDark'
  // template = 'originalDark'
  // template = 'originalLight'
  // const template = 'gradientLight'
  if (template) { document.body.classList.add(template) }

  switch (action.type) {
    case SET_WEB_PUBLIC:
      _state.webPublic = {
        ...action.value,
        template
      }
      return _state
    case SET_WEB_CONFIG:
      _state.webConfig = action.value
      return _state
    case SET_MAINTAIN_DATA:
      _state.maintainData = { ..._state.maintainData, ...action.value } // public接口返回的字段和其他接口有差异，所以取并集
      return _state
    case SET_IS_OPEN_ANNOUNCEMENT:
      _state.isOpenAnnouncement = action.value
      return _state
    case SET_IS_OPEN_LICENSE:
      _state.isOpenLicense = action.value
      return _state
    case SET_IS_CONTACT:
      _state.isContact = action.value
      return _state
    case SET_IS_AUDIO:
      _state.isAudio = action.value
      return _state
    case SET_IS_UNCLAIMED:
      _state.isUnclaimed = action.value
      return _state
    case SET_IS_SHOW_RECHARGE_SUCCESS:
      _state.isShowRechargeSuccess = action.value // PG盘口支付成功弹框
      return _state
    case SET_IS_PIX_PAY_OPEN:
      _state.isPixPayOpen = action.value // PG盘口Pix支付弹框
      return _state
    case SET_IS_OPEN_DAILY:
      _state.isOpenDaily = action.value
      return _state
    case SET_IS_OPEN_EXTRA:
      _state.isOpenExtra = action.value
      return _state
    case SET_FORCE_GAME_DATA:
      _state.forceGameModal = { ..._state.forceGameModal, ...action.value } // 是否弹出强制在app里游戏的弹框，所以取并集
      return _state
    default:
      return _state
  }
}
