// public
export const SET_WEB_PUBLIC = 'SET_WEB_PUBLIC'
export const SET_WEB_CONFIG = 'SET_WEB_CONFIG'
export const SET_MAINTAIN_DATA = 'SET_MAINTAIN_DATA'
export const SET_IS_OPEN_ANNOUNCEMENT = 'SET_IS_OPEN_ANNOUNCEMENT'
export const SET_IS_OPEN_LICENSE = 'SET_IS_OPEN_LICENSE'
export const SET_IS_CONTACT = 'SET_IS_CONTACT'
export const SET_IS_AUDIO = 'SET_IS_AUDIO'
export const SET_IS_UNCLAIMED = 'SET_IS_UNCLAIMED'
export const SET_IS_SHOW_RECHARGE_SUCCESS = 'SET_IS_SHOW_RECHARGE_SUCCESS'
export const SET_IS_PIX_PAY_OPEN = 'SET_IS_PIX_PAY_OPEN'
export const SET_FORCE_GAME_DATA = 'SET_FORCE_GAME_DATA'

// home
export const SET_ANNOUNCEMENT = 'SET_ANNOUNCEMENT'
export const SET_BANNER = 'SET_BANNER'
export const SET_GAME_TYPE_INFO = 'SET_GAME_TYPE_INFO'
// export const SET_HOT_GAME_LIST = 'SET_HOT_GAME_LIST'
export const SET_GAME_LIST = 'SET_GAME_LIST'
export const SET_HOT_GAME = 'SET_HOT_GAME'
export const SET_HAS_CLOSE_ANNOUNCEMENT = 'SET_HAS_CLOSE_ANNOUNCEMENT'
export const SET_RECENT_GAME = 'SET_RECENT_GAME'

// user
export const SET_IS_OPEN_LOGIN = 'SET_IS_OPEN_LOGIN'
export const SET_USERINFO = 'SET_USERINFO'
export const CLEAR_USERINFO = 'CLEAR_USERINFO'
export const SET_VIP_INFO = 'SET_VIP_INFO'
export const SET_IS_USERINFO_OPEN = 'SET_IS_USERINFO_OPEN'
export const SET_LOGIN_REGISTER_TYPE = 'SET_LOGIN_REGISTER_TYPE'
export const SET_PASSWORD = 'SET_PASSWORD'
export const SET_DEVICE_NO = 'SET_DEVICE_NO'
export const SET_IS_CHANGE_PWD_OPEN = 'SET_IS_CHANGE_PWD_OPEN'
export const SET_IS_SHOW_TRIAL_PLAY = 'SET_IS_SHOW_TRIAL_PLAY'
export const SET_IS_SHOW_TRAIL = 'SET_IS_SHOW_TRAIL'
// wallet
export const SET_IS_OPEN_WALLET = 'SET_IS_OPEN_WALLET'
export const SET_PAY_INFO = 'SET_PAY_INFO'
export const SET_WALLET_TYPE = 'SET_WALLET_TYPE'

// activities
export const SET_EXTRA_BONUS = 'SET_EXTRA_BONUS'
export const SET_DAILY_TASK = 'SET_DAILY_TASK'
export const SET_FIRST_RECHARGE_ACTIVITY = 'SET_FIRST_RECHARGE_ACTIVITY'
export const SET_IS_OPEN_WHEEL = 'SET_IS_OPEN_WHEEL'
export const SET_IS_BONUS = 'SET_IS_BONUS'
export const SET_IS_CHECK = 'SET_IS_CHECK'
export const SET_BONUS_CODE = 'SET_BONUS_CODE'
export const SET_PDD_ACTIVITY = 'SET_PDD_ACTIVITY'
export const SET_PDD_ATTEND = 'SET_PDD_ATTEND'
export const SET_PDD_ACTIVITY_OPEN = 'SET_PDD_ACTIVITY_OPEN'
export const SET_BIG_WHEEL = 'SET_BIG_WHEEL'
export const SET_IS_BIG_WHELL_FROM_SIDE_BAR = 'SET_IS_BIG_WHELL_FROM_SIDE_BAR'
export const SET_IS_OPEN_DAILY = 'SET_IS_OPEN_DAILY'
export const SET_IS_OPEN_EXTRA = 'SET_IS_OPEN_EXTRA'
export const SET_GOLD_REWARD = 'SET_GOLD_REWARD'
export const SET_GOLD_AVAILABLE_EXCHANGE_BALANCE = 'SET_GOLD_AVAILABLE_EXCHANGE_BALANCE'
export const SET_APP_DOWNLOAD_DATA = 'SET_APP_DOWNLOAD_DATA'

// records
export const SET_GAME_CATEGORY = 'SET_GAME_CATEGORY'
export const SET_GAME_BET_RECORD = 'SET_GAME_BET_RECORD'
